<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>教务中心</a-breadcrumb-item>
            <a-breadcrumb-item>学员资料</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <zlgx/>
        </div>
    </div>
</template>

<script>
    import zlgx from '@/views/market/xuetang/zlgx'
    export default {
        name:'library',
        components: {
            zlgx,
        },
    }
</script>

<style lang="scss" scoped>

</style>